import React, { ButtonHTMLAttributes } from "react";
import tw, { TwStyle } from "twin.macro";

import { Link } from "gatsby";

import { toTitleCase } from "../../utils/text-utils";
import { FaCircleNotch } from "react-icons/fa";

declare interface buttonProps {
  text: string;
  to?: string;
  href?: string;
  colour?:
    | "default"
    | "primary"
    | "lighter"
    | "lightest"
    | "secondary"
    | "secondary-dark";
  size?: "normal" | "thin";
  full?: boolean;
  loading?: boolean;
  onClick?: () => void;
  customStyles?: TwStyle;
}

const Button: React.FC<buttonProps> = ({
  to,
  href,
  colour = "default",
  size = "normal",
  customStyles,
  full,
  text,
  loading,
  ...rest
}) => {
  const label = toTitleCase(text);
  const css = [
    tw`rounded-md shadow-sm border border-transparent py-3 text-base text-center font-medium focus:outline-none no-underline! whitespace-nowrap`,
    // colours
    colour === "default" &&
      tw`text-gray-900! bg-white transition-colors duration-200 hover:bg-gray-100`,
    colour === "primary" &&
      tw`text-white! bg-dsrTeal-800 transition-colors duration-200 hover:bg-dsrTeal-500`,
    colour === "lighter" &&
      tw`text-white! bg-dsrTeal-700 transition-colors duration-200 hover:bg-dsrTeal-900`,
    colour === "lightest" &&
      tw`text-white! bg-dsrTeal-600 transition-colors duration-200 hover:bg-dsrTeal-700`,
    colour === "secondary" &&
      tw`text-dsrTeal-700! bg-dsrTeal-50 transition-colors duration-200 hover:bg-dsrTeal-200 `,
    colour === "secondary-dark" &&
      tw`text-dsrTeal-700! bg-dsrTeal-100 transition-colors duration-200 hover:bg-dsrTeal-200`,
    // sizes
    size === "normal" && tw`px-8`,
    size === "thin" && tw`px-4`,
    full && tw`w-full block`,
    // extra classes
    customStyles,
  ];
  if (to) {
    return (
      <Link to={to} css={css} {...rest}>
        {label}
      </Link>
    );
  }
  if (href) {
    return (
      <a
        href={href}
        css={css}
        {...rest}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
    );
  }
  return (
    <button
      css={[...css, loading && tw`flex px-3 justify-between items-center`]}
      {...rest}
    >
      {loading && (
        <span tw="mr-1">
          <FaCircleNotch tw="animate-spin" />
        </span>
      )}
      {label}
    </button>
  );
};

export default Button;
