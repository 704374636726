import React from "react";
import tw, { TwStyle } from "twin.macro";

interface dotMatrixProps {
  side: "left" | "right";
  positioning?: TwStyle;
}

const DotMatrix: React.FC<dotMatrixProps> = ({ side, positioning }) => {
  const css = [
    tw`absolute transform`,
    side === "left" && tw`right-full -translate-x-32`,
    side === "right" && tw`left-full translate-x-32`,
    positioning,
  ];
  return (
    <div tw="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div tw="relative h-full text-lg max-w-prose mx-auto">
        <svg
          css={css}
          width="404"
          height="384"
          fill="none"
          viewBox="0 0 404 384"
        >
          <defs>
            <pattern
              id="f210dbf6-a58d-4871-961e-36d5016a0f49"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <circle
                cx="4"
                cy="4"
                r="2"
                tw="text-gray-200"
                fill="currentColor"
              ></circle>
            </pattern>
          </defs>
          <rect
            width="404"
            height="384"
            fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
          ></rect>
        </svg>
      </div>
    </div>
  );
};

export default DotMatrix;
