import React, { useState, useRef, useEffect } from "react";
import tw from "twin.macro";
import { useClickAway } from "ahooks";
import { Link } from "gatsby";

import { Transition } from "../index";
import { navItemProps } from "./Navbar";

const NavItem: React.FC<navItemProps> = ({ label, to, subnavs = [] }) => {
  const [open, setOpen] = useState(false);

  const subnavsMain = subnavs.slice(0, -1);
  const subnavsLast = subnavs[subnavs.length - 1];

  const ref = useRef<HTMLSpanElement>();
  useClickAway(() => {
    setOpen(false);
  }, ref);

  return (
    <>
      <div tw="relative">
        <button
          ref={ref}
          onClick={() => setOpen((prev) => !prev)}
          type="button"
          className="group"
          tw="bg-white focus:outline-none font-medium transition-colors duration-200 hover:text-dsrGray-200 inline-flex items-center rounded-md text-base text-dsrGray-400"
        >
          {to ? <Link to={to}>{label}</Link> : label}
          {!!subnavs.length && (
            <svg
              tw="transition-colors duration-200 group-hover:text-gray-500 h-5 ml-2 text-gray-700 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
        {!!subnavs.length && (
          <Transition
            action="fade"
            show={open}
            classNames={tw`absolute max-w-md mt-3 transform w-auto z-10 lg:-translate-x-1/2 lg:left-1/2 lg:ml-0`}
          >
            <div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div tw="relative gap-6 bg-white sm:gap-8 px-8 pb-8 pt-2">
                {subnavsMain.map(({ to: subTo, label, sublabel }) => (
                  <Link
                    key={label}
                    to={subTo}
                    tw="ml-4 -my-3"
                    onClick={() => setOpen(false)}
                  >
                    <p
                      css={[
                        tw`text-base font-medium text-gray-900 transition-colors duration-200 hover:text-gray-600 whitespace-nowrap`,
                      ]}
                    >
                      {label}
                    </p>
                    {!!sublabel && (
                      <p tw="text-sm text-gray-500 whitespace-nowrap">
                        {sublabel}
                      </p>
                    )}
                  </Link>
                ))}
              </div>
              <div tw="bg-gray-50 sm:gap-8 px-8 py-5 pt-2 -mt-3 space-y-6 sm:space-y-0">
                <Link
                  to={subnavsLast.to}
                  tw="ml-4"
                  onClick={() => setOpen(false)}
                >
                  <p
                    css={[
                      tw`text-base font-medium text-gray-900 transition-colors duration-200 hover:text-gray-600 whitespace-nowrap`,
                    ]}
                  >
                    {subnavsLast.label}
                  </p>
                </Link>
              </div>
            </div>
          </Transition>
        )}
      </div>
    </>
  );
};

export default NavItem;
