import React, { useState, useRef } from "react";
import tw, { css } from "twin.macro";
import slugify from "slugify";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { X as CloseIcon, Menu as MenuIcon, ChevronLeft } from "heroicons-react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { Transition, Button } from "../index";
import NavItem from "./NavItem";
import MobileNavItem from "./MobileNavItem";

import { headerNavs } from "../../config/navs";

export interface navButtonProps {
  label: string;
  to?: string | undefined;
  sublabel?: string;
}

export interface subNavLinks {
  label: string;
  to: string;
  sublabel?: string;
}

export interface navItemProps extends navButtonProps {
  subnavs?: subNavLinks[];
}

interface mobileNavProps {
  open: boolean;
  label: string;
  subnavs: subNavLinks[];
}

interface names {
  node: {
    name: string;
    secondaryName: string;
  };
}

const query = graphql`
  query navbarQuery {
    allStrapiBranchSettings(filter: { group: { name: { eq: "DSR" } } }) {
      edges {
        node {
          name
          secondaryName
        }
      }
    }
  }
`;

const getSidebarHeight = () => {
  if (typeof window !== "undefined") {
    return css`
      bottom: 0;
      top: 72px;
      @media (max-width: 640px) {
        top: 56px;
      }
    `;
  }
  return null;
};

const Navbar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileSubnavOpen, setMobileSubnavOpen] =
    useState<mobileNavProps | null>(null);

  const sidebarRef = useRef();
  const subSidebarRef = useRef();

  const { allStrapiBranchSettings } = useStaticQuery(query);
  const locationsSubnavs = allStrapiBranchSettings.edges.map(
    ({ node: { name, secondaryName } }: names) => ({
      to: `/locations/${slugify(name.toLowerCase())}`,
      label: name,
      sublabel: secondaryName,
    })
  );
  const navs = headerNavs.map((a) => {
    if (a.label === "Locations") {
      return {
        ...a,
        subnavs: [
          ...locationsSubnavs,
          { to: "/locations", label: "View All Locations" },
        ],
      };
    }
    return a;
  });

  // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  const lockBody = (ref: any) => {
    disableBodyScroll(ref.current);
  };

  const unlockBody = () => {
    clearAllBodyScrollLocks();
  };

  return (
    <header tw="sticky top-0 z-30 w-full bg-white border-none shadow-lg">
      <div tw="sticky top-0 left-0 z-30 w-full max-w-screen-md mx-auto bg-white md:max-w-screen-lg lg:max-w-screen-xl">
        <div tw="flex items-center justify-between px-3 py-1 sm:py-3 sm:px-6 md:px-3 md:py-6 lg:px-2 md:gap-x-5 lg:gap-x-10">
          <Link to="/" onClick={() => setMobileMenuOpen(false)}>
            <span tw="sr-only">Home</span>
            <StaticImage
              src="../../images/dsr-logo.png"
              alt="Logo"
              placeholder="blurred"
              layout="constrained"
              loading="eager"
              tw="-mt-2 -mb-1 h-11 w-11"
            />
          </Link>
          <div tw="md:hidden">
            {mobileMenuOpen ? (
              <button
                onClick={() => setMobileMenuOpen(false)}
                type="button"
                tw="inline-flex items-center justify-center p-2 text-gray-400 transition-colors duration-200 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                <span tw="sr-only">Close menu</span>
                <CloseIcon tw="w-8 h-8" />
              </button>
            ) : (
              <button
                onClick={() => setMobileMenuOpen((prev) => !prev)}
                type="button"
                tw="inline-flex items-center justify-center p-2 text-gray-700 transition-colors duration-200 bg-white rounded-md focus:outline-none hover:bg-gray-100 hover:text-gray-500"
              >
                <span tw="sr-only">Open menu</span>
                <MenuIcon tw="w-8 h-8" />
              </button>
            )}
          </div>
          <nav tw="absolute hidden transform -translate-x-1/2 md:flex left-1/2 md:gap-x-5 lg:gap-x-10">
            {navs.map((a) => (
              <NavItem key={a.label} {...a} />
            ))}
          </nav>
          <div tw="hidden md:block">
            <Button
              text="contact us"
              colour="primary"
              size="thin"
              to="/contact-us"
            />
          </div>
        </div>
      </div>
      <Transition
        action="slide-right"
        show={mobileMenuOpen}
        classNames={tw`fixed top-0 bottom-0 z-20 w-screen overflow-y-auto`}
        beforeLeave={unlockBody}
        afterEnter={() => lockBody(sidebarRef)}
      >
        <div
          ref={sidebarRef}
          onClick={(e) => e.stopPropagation()}
          css={[
            getSidebarHeight(),
            tw`absolute flex flex-col justify-between w-screen overflow-y-auto bg-dsrGray-600`,
          ]}
        >
          <div
            css={[
              tw`z-30 flex flex-col divide-y divide-dsrGray-400`,
              getSidebarHeight(),
            ]}
          >
            {navs.map(({ label, subnavs, to }) => (
              <div key={label} onClick={() => setMobileMenuOpen(!!subnavs)}>
                <MobileNavItem
                  label={label}
                  to={to}
                  subnavs={subnavs}
                  setMobileSubnavOpen={setMobileSubnavOpen}
                />
              </div>
            ))}
            <div tw="mx-4 mt-4 border-none">
              <Button
                full
                colour="lightest"
                text="Contact Us"
                to="/contact-us"
                onClick={() => setMobileMenuOpen(false)}
              />
            </div>
          </div>
        </div>
      </Transition>
      <Transition
        action="slide-right"
        show={!!mobileSubnavOpen?.open && !!mobileMenuOpen}
        classNames={tw`fixed top-0 bottom-0 z-20 w-screen overflow-y-auto`}
        afterLeave={() => setMobileSubnavOpen(null)}
        beforeLeave={unlockBody}
        afterEnter={() => lockBody(subSidebarRef)}
      >
        <div
          ref={subSidebarRef}
          css={[
            getSidebarHeight(),
            tw`absolute flex flex-col justify-between w-screen overflow-y-auto bg-dsrGray-600`,
          ]}
        >
          <div css={[getSidebarHeight(), tw`flex flex-col`]}>
            <div tw="flex w-full px-4 py-4 text-base font-medium text-white">
              <span>
                <ChevronLeft
                  tw="absolute top-4 left-2"
                  onClick={() =>
                    setMobileSubnavOpen((prev) => ({ ...prev, open: false }))
                  }
                />
              </span>
              <span tw="mx-auto font-semibold text-center">
                {mobileSubnavOpen?.label}
              </span>
            </div>
            {mobileSubnavOpen?.subnavs?.map((a) => (
              <div key={a.label} onClick={() => setMobileMenuOpen(false)}>
                <MobileNavItem {...a} isSub />
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </header>
  );
};

export default Navbar;
