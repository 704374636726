import React from "react";
import "twin.macro";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { getSnippetFromHtml } from "../../utils/text-utils";
import { Button } from "..";
import tw from "twin.macro";

const JobCard = (props) => {
  const { location: locationText, content, image, role, slug } = props;
  const fullSlug = `/careers/${slug}`;

  return (
    <div tw="rounded-lg shadow-lg md:flex-basis[50%] lg:flex-basis[33%] my-8 flex flex-col">
      <Link to={fullSlug} tw="flex-shrink-0 -mb-2">
        <GatsbyImage
          tw="h-48 w-full"
          image={getImage(image.localFile)}
          alt={image.alternativeText || `${role} hero image`}
          imgStyle={{
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
          }}
        />
      </Link>
      <div tw="bg-white p-6 flex flex-col flex-grow justify-between">
        <div>
          <Link to={fullSlug} tw="block mb-2">
            <p tw="font-semibold text-dsrGray-900 text-xl">{role}</p>
          </Link>
          <p tw="font-medium text-dsrTeal-600 text-sm">{locationText}</p>
          <p tw="mt-3 text-base text-dsrGray-400">
            {getSnippetFromHtml(content)}
          </p>
        </div>
        <Button
          text="Apply Now"
          colour="lightest"
          to={fullSlug}
          customStyles={tw`block mt-4`}
        />
      </div>
    </div>
  );
};

export default JobCard;
