import React from "react";
import tw, { css } from "twin.macro";

interface servicesCardProps {
  header: string;
  description: string;
  items: {
    name: string;
    description: string;
    pricePounds: number;
  }[];
}

const getDisplayPrice = (mainPrice) => {
  return Intl.NumberFormat(undefined, {
    currency: "GBP",
    minimumFractionDigits: 2,
    style: "currency",
  }).format(parseFloat(mainPrice ? mainPrice : 0));
};

const descStyles = css`
  & p {
    ${tw`my-0 text-gray-500!`}
  }
  & p span {
    ${tw`text-gray-500!`}
  }
  & a {
    ${tw`text-dsrTeal-800 transition-colors duration-200 hover:text-dsrTeal-500`}
  }
`;

const ServicesCard: React.FC<servicesCardProps> = ({
  header,
  description,
  items,
}) => {
  return (
    <div tw="bg-white shadow overflow-hidden rounded-lg">
      <div tw="px-4 py-3 sm:px-6">
        <h3 tw="text-lg font-medium text-gray-900 my-3!">{header}</h3>
        <div
          css={[tw`max-w-2xl text-sm text-gray-500 -mt-4!`]}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div tw="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl tw="sm:divide-y sm:divide-gray-200">
          {items.map(({ name, description: desc, pricePounds }) => (
            <div
              key={name}
              tw="py-4 sm:gap-4 sm:grid sm:grid-cols-2 sm:px-6 sm:py-5"
            >
              <p tw="font-medium text-gray-900 text-sm my-0!">
                {`${name} - ${getDisplayPrice(pricePounds)}`}
              </p>
              <dd tw="mt-1 text-gray-500! text-sm sm:my-0">
                <div
                  css={[descStyles]}
                  dangerouslySetInnerHTML={{ __html: desc }}
                />
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default ServicesCard;
