import "@fontsource/nunito-sans/400.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans/700.css";
import "../styles/global.css"

import * as React from "react";
import { GlobalStyles, css } from "twin.macro";
import { Global } from "@emotion/react";
import { Navbar, Footer } from "../components";
import { ModalContextProvider } from "../context/useModal";

import Modal from "../components/modal/Modal";

const layer = css`
  html {
    -webkit-tap-highlight-color: transparent;
  }
`;

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Global styles={layer} />
      <ModalContextProvider>
        <Navbar />
        {children}
        <Footer />
        <Modal />
      </ModalContextProvider>
    </>
  );
};

export default Layout;
