import React from "react";
import "twin.macro";

interface practiceStatsProps {
  exams: string;
  header: string;
  patients: string;
  years: string;
  subheader?: string;
}

const PracticeStats: React.FC<practiceStatsProps> = ({
  exams,
  header,
  patients,
  years,
  subheader,
}) => {
  return (
    <div tw="bg-gray-50 pt-12 sm:pt-16">
      <div tw="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div tw="max-w-4xl mx-auto text-center">
          <h2 tw="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {header}
          </h2>
          {!!subheader && (
            <p tw="mt-3 text-xl text-gray-500 sm:mt-4">{subheader}</p>
          )}
        </div>
      </div>
      <div tw="mt-10 pb-12 bg-white sm:pb-16">
        <div tw="relative">
          <div tw="absolute inset-0 h-1/2 bg-gray-50"></div>
          <div tw="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div tw="max-w-4xl mx-auto">
              <dl tw="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <div tw="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt tw="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Years
                  </dt>
                  <dd tw="font-extrabold order-1 text-5xl text-dsrTeal-600">
                    {years}
                  </dd>
                </div>
                <div tw="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt tw="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Patients
                  </dt>
                  <dd tw="font-extrabold order-1 text-5xl text-dsrTeal-600">
                    {patients}
                  </dd>
                </div>
                <div tw="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt tw="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    Eye Exams
                  </dt>
                  <dd tw="font-extrabold order-1 text-5xl text-dsrTeal-600">
                    {exams}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PracticeStats;
