import React from "react";
import "twin.macro";
import { Button } from "../index";

declare interface homepageAnnouncementProps {
  topic: string;
  header: string;
  summary: string;
}

const HomepageAnnouncement: React.FC<homepageAnnouncementProps> = ({
  topic,
  header,
  summary,
}) => {
  return (
    <section tw="bg-dsrTeal-900">
      <div tw="container mx-auto py-20">
        <div tw="flex flex-wrap items-center mb-4">
          <div tw="w-full p-4 lg:flex-1">
            <h2 tw="font-semibold text-base text-dsrTeal-400 tracking-wider uppercase">
              {topic}
            </h2>
            <p tw="capitalize leading-tight mb-2 text-4xl font-bold text-white">
              {header}
            </p>
            <p tw="text-base text-dsrGray-100">{summary}</p>
          </div>
          <div tw="w-full p-4 lg:w-auto">
            <Button colour="primary" text="Find Out More" to="/announcements" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageAnnouncement;
