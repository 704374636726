import React from "react";
import "twin.macro";
import { Button } from "../index";

const CTA: React.FC = () => (
  <section tw="bg-white">
    <div tw="max-w-7xl mx-auto px-4 py-12 text-center sm:px-6 lg:px-8 lg:py-16">
      <h2 tw="font-bold text-3xl text-dsrGray-900 tracking-tight sm:text-4xl">
        <span tw="block">Detailed eye examinations</span>
        <span tw="block">Providing peace of mind.</span>
      </h2>
      <div tw="mt-8 flex justify-center space-x-3">
        <Button text="Book Now" colour="lighter" to="/locations" />
        <Button text="Eye Exams" colour="secondary" to="/eye-examinations" />
      </div>
    </div>
  </section>
);

export default CTA;
