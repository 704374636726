import React from "react";
import "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import BlogCard from "./BlogCard";

const query = graphql`
  query BlogCardBlockQuery {
    allStrapiArticle(
      limit: 4
      sort: { fields: publishedAt, order: DESC }
      filter: { groups: { elemMatch: { name: { eq: "DSR" } } } }
    ) {
      edges {
        node {
          content
          author {
            picture {
              alternativeText
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 50)
                }
              }
            }
            name
          }
          category {
            name
          }
          description
          image {
            alternativeText
            id
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
          publishedAt
          title
          slug
        }
      }
    }
  }
`;

const BlogCardBlock = ({ slug }: { slug?: string }) => {
  const data = useStaticQuery(query);
  const blogPosts = data.allStrapiArticle.edges.map((a) => a.node);
  const filteredPosts = slug
    ? blogPosts.filter((a) => a.slug !== slug).slice(0, 3)
    : blogPosts.slice(0, 3);
  return (
    <section tw="bg-dsrTeal-50">
      <div tw="container mx-auto">
        <div tw="pb-20 pt-16 px-4 relative sm:px-6 lg:pb-28 lg:pt-24 lg:px-8">
          <div tw="absolute inset-0">
            <div tw="h-1/3 sm:h-2/3"></div>
          </div>
          <div tw="relative max-w-7xl mx-auto">
            <div tw="text-center">
              <h2 tw="font-bold text-3xl text-dsrGray-900 tracking-tight sm:text-4xl">
                {`${
                  slug?.indexOf("careers/") === -1 ? "More From" : "From"
                } The Blog`}
              </h2>
              <p tw="max-w-2xl mt-3 mx-auto text-dsrGray-400 text-xl sm:mt-4">
                Catch up with the latest in eyecare and eyewear
              </p>
            </div>
            <div tw="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {filteredPosts.map((post) => (
                <BlogCard key={post.title} {...post} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogCardBlock;
